<template>
  <div class="body">
    <div class="info">每日收益</div>
    <div class="header">

      <div>
        <div style="color: #BFBF00">￥{{cumulative_income}}</div>
        <div>累计收益</div>
      </div>
      <div>
        <div style="color: #797979">￥{{withdrawal_balance}}</div>
        <div>可提现余额</div>
      </div>
      <div>
        <div style="color: #027DB4" @click="revenue_withdrawal()">
          提现记录
        </div>
        <div>
          <el-button type="primary" class="btn" @click="dialogFormVisible = true">提现</el-button>
        </div>
      </div>
    </div>
    <div class="table">
      <el-table :data="bonus_list" border class="fatherBox">
        <el-table-column prop="date" label="日期"></el-table-column>
        <el-table-column prop="zsy" label="收益额"></el-table-column>
        <el-table-column prop="ts_bonus" label="广告收益"></el-table-column>
        <el-table-column prop="ts_read" label="广告推送量"></el-table-column>
        <el-table-column prop="yyp_bonus" label="音视频收益"></el-table-column>
        <el-table-column prop="yyp_num" label="音视频订购人次"></el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="detail(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">

        <el-pagination @current-change="CurrentChange" :page-size="pagesize" background
          layout="total, prev, pager, next, jumper" :current-page="currpage" :total="CountNum"
          style="margin-top:20px;text-align: right;">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="提现" :visible.sync="dialogFormVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:460px;margin:0 auto">
        <el-form-item label="请输入提现金额">
          <!-- <el-input style="width: 260px; float: left" placeholder="请输入提现金额" v-model="num"> -->
            <el-input-number v-model="num"  :controls="false" :precision="0" :min="1" :max="99999999" label="请输入提现金额"></el-input-number>
          </el-input>
        </el-form-item>
        <el-form-item label="请选择提现类型">
          <el-select v-model="value" placeholder="请选择提现类型" style="width: 260px; float: left">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="dialogFormVisible = false" style="margin-left: 20px;">取消</el-button>
          <el-button type="primary" @click="next_step()" style="margin-left: 180px;">下一步</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="提现" :visible.sync="verification" top="30px">
      <span>提现需要向手机 <p style="color:#1890FF;display:inline">{{phone}}发送验证码</p></span>
      <div class="codies">
        <p>请输入验证码：</p>
        <el-input v-model="check_code" style="width: 160px; float: left" placeholder="请输入验证码"></el-input>
        <span class="sendyzm" @click="sendYZM" v-show="show">发送验证码</span>
        <span class="sendyzm" v-show="!show" style="color: #cfcfcf">
          {{ count + "秒" + "后重发"}}
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="verification = false">取 消</el-button>
        <el-button type="primary" @click="next_notice">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提现" :visible.sync="dialogNoticeVisible" top="50px" :close-on-click-modal="false">
      <el-form ref="articleForm" style="width:200px;margin:0 auto">
        <el-form-item label="操作成功">
        </el-form-item>
        <el-form-item label="等待管理员审核">
        </el-form-item>
        <el-form-item style="margin-top:5px">
          <el-button @click="notice_yes" style="margin:0 auto;">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
  import {
    agentProfitday,
    agentProfitmoney,
    agentProfitsum,
    extractApply,
    personInfo
  } from '@/api/agentApi.js'
  import {
    sendSmsNew,
  } from '@/api/TeacherApi.js'

  export default {
    data() {
      return {
        bonus_list: [], // 学生列表
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        isContinue: 0, //是否有下一页
        dialogFormVisible: false, // 提现弹窗
        verification: false, //发送验证码 弹窗
        dialogNoticeVisible: false, // 通知弹窗
        cumulative_income: 0, //累计收益
        withdrawal_balance: 0, //可提现余额
        options: [],
        value: '', //select 选择值
        num: 0, //提现金额
        phone: '', //当前账号手机号
        moblie: '', //当前账号手机号
        check_code: '', //验证码
        count: '', //倒计时-数
        show: true, // 倒计时(秒)
        timer: null, // 验证码标识
        ActiveTel: '', // 当前账号手机号
        loading: false,
      }
    },
    created() {
      if (localStorage.getItem('dailyRevenue') != 'null') {
        this.currpage = parseInt(localStorage.getItem('dailyRevenue'));
        localStorage.setItem('dailyRevenue', null)
      }
    },
    mounted() {
      const newMoblie = JSON.parse(localStorage.getItem('userInfo'))
      
      if(newMoblie.userType == 'agentAdv'){
        this.options =
         [{
          value: '1',
          label: '提现到银行卡'
        },{
          value: '2',
          label: '提现到广告平台'
        },]
      }else{
        this.options =
         [{
          value: '1',
          label: '提现到银行卡'
        },]
      }

      // this.ActiveTel = newMoblie.tel // 当前账号手机号
      // this.ActiveTel = 13795112596 // 测试当前账号手机号 上线要注释 开启上面的
      this.bonusList(this.currpage); // 查询省市区
      this.agentProfitmoney();
      this.agentProfitsum();
      this.person_info()// 获取代理信息
    },
    methods: {
      //获取代理信息
      person_info() {
        const data = {};
        personInfo(data).then((res) => {
            if (res.status.code == 1) {
              this.ActiveTel = res.data.tel;
            } else {
              this.$message.error(res.status.msg);
            }
          })
          .catch((err) => {
            console.log(res);
          });
      },
      bonusList(currpage) { //查询所有学生
        const data = {
          limit: this.pagesize,
          page: currpage
        }
        agentProfitday(data).then(res => {
          this.CountNum = parseInt(res.data.countNum); // 总条数
          this.currpage = currpage; //分页显示当前页
          this.bonus_list = res.data.list;
          this.isContinue = res.data.isContinue
        }).catch(err => {
          console.log(err)
        })
      },
      next_step() {
        if (this.num <= 0) {
          this.$message.error('请填写可提现金额')
          return
        }
        if (this.value <= 0) {
          this.$message.error('请选择提现类型')
          return
        }
        this.dialogFormVisible = false; // 添加设备弹窗
        this.verification = true;
        let NewMoblie = this.ActiveTel;
        NewMoblie += '';
        const NewTel = NewMoblie.replace(NewMoblie.substring(3, 7), '****');
        this.phone = NewTel;
        this.moblie = this.ActiveTel;


      },
      sendYZM() { // 倒计时结束后发送验证码
        const data = {
          phone: this.moblie,
          type: 3 //	短信类型 ( 1老师修改手机号 3代理解除设备绑定 )
        }
        // 原来sendSms(data).then(res => { // 重新开始倒计时
        sendSmsNew(data).then(res => { // 重新开始倒计时
          if (res.status.code == 1) {
            this.$message({
              message: '发送成功',
              type: 'success'
            })
            this.YZMsend()
          } else {
            this.$message.error(res.status.msg)
          }
        }).catch(err => {
          console.log(err)
        })
      },
      YZMsend() { // 验证码
        const TIME_COUNT = 60
        if (!this.timer) {
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          }, 1000)
        }
      },
      next_notice() {
        //申请提现
        if (this.check_code == '') {
          this.$message.error('请填写验证码')
          return
        }
        this.loading = true;
        const data = {
          num: this.num,
          type: this.value,
          code: this.check_code,
        };
        extractApply(data).then(res => {
          if (res.status.code == 1) {

            this.loading = false;
            this.$message({
              message: '申请成功',
              type: 'success'
            })
            this.verification = false,
            this.dialogNoticeVisible = true
            this.agentProfitmoney();
          } else {
            this.$message.error(res.status.msg)
          }

        }).catch(err => {
          console.log(err)
        })


      },

      notice_yes() {
        this.dialogNoticeVisible = false;
        this.revenue_withdrawal();
      },
      agentProfitmoney() {
        const data = {};
        this.loading = true;
        agentProfitmoney(data).then(res => {

          this.withdrawal_balance = res.data.list.advProfitNum
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },
      agentProfitsum() {
        const data = {};
        this.loading = true;
        agentProfitsum(data).then(res => {
          // 已审核用户列表
          this.cumulative_income = res.data.zsy;
          this.loading = false;
        }).catch(err => {
          console.log(err)
        })
      },
      stuListSearchHandle(currpage) { // 带有查询时分页 当有条件查询 分页时走这个方法
        this.loading = true;

        const data = {
          page: currpage, // 第一次查询时候默认1
          limit: this.pagesize, // 总数
        }

        agentProfitday(data).then(res => {
          this.bonus_list = res.data.list // 已审核用户列表
          //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
          this.CountNum = parseInt(res.data.countNum); // 总条数
          this.currpage = currpage; //分页显示当前页
          this.isContinue = res.data.isContinue
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      CurrentChange(val) { // 分页
        if (this.isContinue == 1) {
          this.currpage = val
          this.stuListSearchHandle(val)
        }
      },
      revenue_withdrawal(row) { // 点击详情

        localStorage.setItem('Route', 'dailyRevenue') // 存跳转标识
        localStorage.setItem('dailyRevenue', this.currpage) // 存跳转页面
        this.$router.push({
          name: 'revenue_withdrawal',
          params: {}
        })
      },
      detail(row) { // 点击详情

        localStorage.setItem('Route', 'dailyRevenue') // 存跳转标识
        localStorage.setItem('dailyRevenue', this.currpage) // 存跳转页面
        this.$router.push({
          name: 'revenue_details',
          params: {
            date: row.date
          }
        })
      },
    }
  }

</script>

<style lang="scss" scoped>
  * {
    text-align: left;
  }

  .header {
    display: flex;
    align-items: center;
    float: right;

    div {

      margin: 0, auto;
      margin-top: 10px;
      margin-right: 50px;
      text-align: center;

      div {

        margin-top: 10px;
        text-align: center;
      }
    }


  }

  .info {
    margin-left: 50px;
    font-size: 20px;
    font-weight: bold;
  }

  .table {
    padding: 20px 50px;
    margin-top: 80px;

    .info {
      font-size: 20px;
      font-weight: bold;

    }

    .search {
      display: flex;
      align-items: center;

      .time {
        margin-left: 20px;
        width: 300px;
      }
    }

  }

  .codies {
    display: flex;
    width: 526px;
    margin: 0 auto;
    margin-top: 16px;
  }

  .sendyzm {
    width: 100px;
    height: 40px;
    float: left;
    margin-left: 10px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    color: yellowgreen;
    border: 1px solid #cccccc;
  }

</style>
